<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs" :actions="actions"></pui-datatable>
	</div>
</template>

<script>
import AlertActions from './AlertActions.js';
export default {
	name: 'alert-grid',
	components: {},
	data() {
		return {
			modelName: 'alert',
			actions: AlertActions.gridactions,
			modelColumnDefs: {
				read: {
					render: (data, type, row) => {
						if (!data) {
							return this.$t('common.no');
						}
						return this.$t('common.yes');
					}
				}
			}
		};
	}
};
</script>
